import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../../components/Layout'
import ServiceFooter from '../../../components/ServiceFooter'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Gate = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`コインパ予約 | ${title}`}</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>コインパ予約</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /
          <li>
            <Link to={`/service/qtnetservice`}>
              駐車場利用者向けサービス「QT-netサービス」
            </Link>
          </li>
          /<li>コインパ予約</li>
        </div>
      </div>

      <div className="main-wrapper qtservice booking">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>コインパ予約</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              一部対応駐車場で予約が可能です。予約は利用時間の2時間前から。出庫時には、登録したクレジットカードで自動で精算されるため、手間なく出庫できます。
            </h4>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/dokodemopay.webp"
              />
              <img
                className="sp-only top"
                src="/assets/images/qtnet-service/dokodemopay.png"
                width="5901288"
                height="710"
                alt=""
              />
            </picture>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/dokodemopay-pc.webp"
              />
              <img
                className="pc-only top full"
                src="/assets/images/qtnet-service/dokodemopay-pc.png"
                width="5901288"
                height="710"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>目次</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <ul>
              <li>
                <AnchorLink to={`/service/qtnetservice/booking#top`}>
                  利用方法
                </AnchorLink>
                <ul>
                  <li>
                    <AnchorLink to={`/service/qtnetservice/booking#download`}>
                      アプリのダウンロード
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/booking#registration`}
                    >
                      会員情報/クレジットカード登録
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink
                      to={`/service/qtnetservice/booking#app-booking`}
                    >
                      アプリで検索、予約
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to={`/service/qtnetservice/booking#park`}>
                      入庫
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to={`/service/qtnetservice/booking#payment`}>
                      出庫/精算
                    </AnchorLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div id="top" className="row">
          <div className="h-wrapper">
            <h3>利用方法</h3>
          </div>
        </div>
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div id="download" className="row">
          <div className="h-wrapper">
            <h3>1. アプリのダウンロード</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <div className="green-frame">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>
          </div>
        </div>

        <div id="registration" className="row">
          <div className="h-wrapper">
            <h3>2. 会員情報/クレジットカード登録</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>①会員情報を登録</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/registration-1.webp"
              />
              <img
                className="registration-01 aligncenter"
                src="/assets/images/qtnet-service/registration-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>②クレジットカード情報を登録</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/registration-2.webp"
              />
              <img
                className="registration-02 aligncenter"
                src="/assets/images/qtnet-service/registration-2.png"
                width="590"
                height="710"
                alt=""
              />
            </picture>

            <div className="btn-gr">
              <Link to={`/service/qtnetservice/registration`}>
                <p className="title">詳しく見る</p>
              </Link>
            </div>
          </div>
        </div>

        <div id="app-booking" className="row">
          <div className="h-wrapper">
            <h3>3. アプリで検索、予約</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>①「駐車場検索・コインパ予約」を選択</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/booking-1.webp"
              />
              <img
                className="booking-01 aligncenter"
                src="/assets/images/qtnet-service/booking-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>②「空室あり」「予約可能」で駐車場を絞り込み</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/booking-2.webp"
              />
              <img
                className="booking-02 aligncenter"
                src="/assets/images/qtnet-service/booking-2.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>③駐車場名、料金などを確認し、「予約手続きへ」をタップ</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/booking-3.webp"
              />
              <img
                className="booking-03 aligncenter"
                src="/assets/images/qtnet-service/booking-3.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>④注意事項を確認し、ナンバーを入力。「予約する」をタップ</h5>
            <h6>※車室を指定したい場合は、車室（オプション）を入力</h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/booking-4.webp"
              />
              <img
                className="booking-04 aligncenter"
                src="/assets/images/qtnet-service/booking-4.png"
                width="590"
                height="875"
                alt=""
              />
            </picture>

            <div className="additional">
              <p className="title">▼予約料金について</p>
              <p>
                ・予約開始から入庫あるいはキャンセルまでの時間も駐車料金が発生します（予約料金）。最大料金は入庫後からの適用となりますのでご注意ください。
              </p>
              <p className="title">▼他のお客様が間違って入庫してしまった場合</p>
              <p>
                ・「コインパ予約」は、駐車場の車室を完全に確保するものではございません。他のお客様が間違って入庫してしまう場合もございますのでご了承ください。この場合、予約料金は間違って入庫されたお客様にご請求します。
              </p>
            </div>

            <h5>⑤予約完了</h5>
            <h6>
              アプリのトップ画面に予約内容が表示されます。車室を選択しなかった場合、トップ画面にて車室が指定されるので、その車室に入庫してください。
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/booking-5.webp"
              />
              <img
                className="booking-05 aligncenter"
                src="/assets/images/qtnet-service/booking-5.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>予約エラーについて</h5>
            <h6>
              下記のような場合にエラーとなります。
              <br />
              ・指定⾞室が空⾞状態でない場合
              <br />
              ・満⾞の場合
              <br />
              <br />
              エラーになった場合は、予約は完了していません。再度空いている車室の予約手続きをおこなってください。
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/booking-error.webp"
              />
              <img
                className="booking-error aligncenter"
                src="/assets/images/qtnet-service/booking-error.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>予約のキャンセル方法</h5>
            <h6>
              アプリトップの予約中の画面より「キャンセルする」をタップし、キャンセル手続きを行ってください。手続きが完了すると予約からキャンセルまでの時間の予約料金が精算されます。
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/booking-cxl.webp"
              />
              <img
                className="booking-cxl aligncenter"
                src="/assets/images/qtnet-service/booking-cxl.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="park" className="row">
          <div className="h-wrapper">
            <h3>4. 入庫</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>①予約した車室に駐車したら、スマホで入庫を確定する</h5>

            <h5>
              プッシュ通知をONにすると、プッシュ通知で、入庫確認通知が届きます
            </h5>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/nyuko-1.webp"
              />
              <img
                className="nyuko-01 aligncenter"
                src="/assets/images/qtnet-service/nyuko-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>
              または、アプリトップ画面のステータスから「入庫確認・キャンセルする」をタップ
            </h5>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/nyuko-cxl.webp"
              />
              <img
                className="nyuko-cxl aligncenter"
                src="/assets/images/qtnet-service/nyuko-cxl.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>②入庫確認に返答する</h5>
            <h6>
              ・ご自身である場合→「✓入庫しました」をタップ
              <br />
              ・ご自身でない場合→「×入庫していません」をタップ
            </h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/nyuko-reply.webp"
              />
              <img
                className="nyuko-reply aligncenter"
                src="/assets/images/qtnet-service/nyuko-reply.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <div className="additional">
              <p className="title">
                ▼入庫していないのに「入庫しました」を押した場合
              </p>
              <p>
                ・誤って入庫した方の駐車料金を支払うことになります。その方が現金精算をした場合は、支払いは発生しません。
              </p>
              <p className="title">
                ▼入庫したのに「入庫していません」を押した場合
              </p>
              <p>・乗り逃げ扱いとなり、後日請求させていただきます。</p>
            </div>

            <h5>③予約車室への入庫が確定</h5>
            <h6>予約から出庫までの料金がお客様へご請求されます。</h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/nyuko-confirm.webp"
              />
              <img
                className="nyuko-confirm aligncenter"
                src="/assets/images/qtnet-service/nyuko-confirm.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>※「×入庫していません」を押した場合</h5>
            <h6>
              この場合は、予約取り消しになります。
              <br />
              予約開始～予約取り消しまでの間の駐車料金はお客様には発生しません。
              <br />
              再度予約したい場合は、予約の取り直しをお願いします。
            </h6>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/nyuko-not.webp"
              />
              <img
                className="nyuko-not aligncenter"
                src="/assets/images/qtnet-service/nyuko-not.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div id="payment" className="row">
          <div className="h-wrapper">
            <h3>5. 出庫/精算</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>そのまま出庫するとクレジットカードで自動精算完了</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/seisan.webp"
              />
              <img
                className="seisan aligncenter"
                src="/assets/images/qtnet-service/seisan.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>6. 領収書発行</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>
              ①アプリのトップから「【コインパ予約/ナンバーペイ】利用履歴」を押す
            </h5>
            <h6>
              ※現金で精算した場合は、精算機から領収書を発行してください。アプリでは領収書のダウンロードはできません
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/reciept-1.webp"
              />
              <img
                className="reciept-01 aligncenter"
                src="/assets/images/qtnet-service/reciept-1.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>②対象の利用を探して、「領収書の表示」を押す</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/reciept-2.webp"
              />
              <img
                className="reciept-02 aligncenter"
                src="/assets/images/qtnet-service/reciept-2.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>③領収書PDFを保存</h5>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/reciept-3.webp"
              />
              <img
                className="reciept-03 aligncenter"
                src="/assets/images/qtnet-service/reciept-3.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>7. その他</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <h5>利用履歴から再び予約をする方法</h5>
            <h6>
              「【コインパ予約/ナンバーペイ】利用履歴」メニューから、予約したい駐車場名をタップすると、そのまま予約手続き画面へ進めます。
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/re-booking.webp"
              />
              <img
                className="re-booking aligncenter"
                src="/assets/images/qtnet-service/re-booking.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>

            <h5>予約失効について</h5>
            <h6>
              予約完了から2時間で予約は失効します。2時間以内に入庫されなかった場合、その間の駐車料金はクレジットカードから自動精算されます。
            </h6>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/qtnet-service/expired-booking.webp"
              />
              <img
                className="expired aligncenter"
                src="/assets/images/qtnet-service/expired-booking.png"
                width="590"
                height="693"
                alt=""
              />
            </picture>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>アプリのダウンロードはこちら</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p className="sm12">
              駐車場の、検索/予約/精算ができる便利な駐車場アプリ。駐車場利用に応じてポイントが貯まり、お得に駐車場をご利用いただけます。
            </p>

            <div className="green-frame bottom">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/qtnet-service/qt-logo.webp"
                />
                <img
                  className="qt-logo"
                  src="/assets/images/qtnet-service/qt-logo.png"
                  width="301"
                  height="116"
                  alt=""
                />
              </picture>
              <h5>
                「QT-net 駐車場ツール」を
                <br />
                いますぐダウンロード
              </h5>

              <div className="flex-wrap">
                <div className="flex-item">
                  <p className="flex-item-title">iPhone版</p>
                  <a
                    href="https://apps.apple.com/jp/app/qt-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E3%83%84%E3%83%BC%E3%83%AB/id563447907"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-apple.webp"
                      />
                      <img
                        className="dl-apple"
                        src="/assets/images/qtnet-service/dl-apple.png"
                        width="425"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
                <div className="flex-item">
                  <p className="flex-item-title">Andoroid版</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=jp.co.itechcorp.android.parkingtool&hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="/assets/images/qtnet-service/dl-google.webp"
                      />
                      <img
                        className="dl-google"
                        src="/assets/images/qtnet-service/dl-google.png"
                        width="402"
                        height="120"
                        alt=""
                      />
                    </picture>
                  </a>
                </div>
              </div>

              <p className="note">
                ※対応OSは、iOSおよびAndroidTMです。ただし、一部非対応のバージョンおよび端末があります。
                <br />
                ※"iOS"、"App Store"はApple Inc.の商標です。
                <br />
                ※"AndroidTM"、"Google PlayTM"はGoogle
                Inc.の商標または登録商標です。
                <br />
                ※一部のサービスがご利用できない店舗がございます。
              </p>
            </div>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Gate
